import * as React from "react";
import "./pricing.css";
import Typography from "@mui/material/Typography";
import DefaultImg from '../../../../assets/pricing-header/pricing- header.png';
// import PricingImg from "../../../../assets/family.png";
import PricingImg from "../../../../assets/family-pet1.png";
import { Grid, Button, TableContainer, Table, TableBody, TableRow, TableCell, Modal, Alert, Divider, Select, MenuItem, FormControl, useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";
import greenTick from "../../../../assets/icons/green-tick.svg"
import redTick from "../../../../assets/icons/red-tick.svg"
import wrong from "../../../../assets/icons/wrong.svg"
import info from "../../../../assets/icons/info-icon.svg"
import close from "../../../../assets/icons/close-icon.svg"
import { pricingSubscription } from "../redux/actions";

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import MYREDFOLDER from "../../../../assets/MyRedFolderLogo.png";
import WalmartLogo from "../../../../assets/icons/Walmart_logo.png";



const CustomisedPricing = () => {
  const tableFirstCell = {
    border: '1px solid #e0e0e0', color: "black", backgroundColor: '#F5F5F5'
  }
  const tableCell = {
    border: '1px solid #e0e0e0', color: "black", backgroundColor: '#fff'
  }
  const eventableCell = {
    border: '1px solid #e0e0e0', color: "black", backgroundColor: "#FFFDF7",
  }

  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState();
  const customisedBannerTitle = "Walmart Pricing" || "Pricing"
  const customisedBannerImg = PricingImg || DefaultImg


  const handleClose = () => {
    setOpen(false);
  };

  const handleopen = () => {
    setOpen(true);
  };

  const isMobile = useMediaQuery('(max-width: 800px)');

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FF8620',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "black",
      backgroundColor: '#FFE8AA',
      border: ' 1px solid #FF8620 ',
      borderRadius: "7px",
      // maxWidth: 200,
      maxWidth: isMobile ? 200 : 400,
      padding: isMobile ? "8px" : "15px",
      fontSize: isMobile ? "12px" : "16px",
      fontWeight: 400


    },
  }));


  const RenderPopup = (props) => {
    const { onClose, onopen, planvalue } = props
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: "",
      emailId: '',
      contactNo: "",
      plan: planvalue
    });
    const currentURL = new URL(window.location.href);
    const pathname = currentURL?.pathname;

    const [isWalmart, setIsWalmart] = useState(pathname === "/walmart")
    const [nameError, setNameError] = useState("");
    const [lastnameError, setLastNameError] = useState("");
    const [numError, setNumError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [couponError, setCouponError] = useState(false)
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const validateName = (name) => {
      const nameRegex = /^[a-zA-Z\s']+$/;
      return nameRegex.test(name);
    };



    const validateNum = (num) => {
      const phonePattern = /^\d{10}$/;
      return phonePattern.test(num);
    };

    const validateEmail = (email) => {
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(email);
    };


    const handleSubmit = (event) => {
      event.preventDefault();

      const isNameValid = validateName(formData.firstName);
      const isLastNameValid = validateName(formData.lastName);
      const isNumValid = validateNum(formData.contactNo);
      const isEmailValid = validateEmail(formData.emailId);
      const couponCode = formData.couponCode?.trim(); 
      const isValidCoupon = couponCode?.length > 0 ? couponCode === "WALMARTMRF-60OFF" : true;

      if (!isNameValid && !isEmailValid && !isNumValid && !isLastNameValid) {
        setNameError('Please enter a valid Firstname.');
        setLastNameError('Please enter a valid Lastname.');
        setNumError('Please enter a valid Number (10 digits).');
        setEmailError('Please enter a valid email address.');
        return;
      }
      else if (!isNameValid) {
        setNameError('Please enter a valid Firstname.');
        return;
      }
      else if (!isLastNameValid) {
        setLastNameError('Please enter a valid Lastname.');
        return;
      }
      else if (!isNumValid) {
        setNumError('Please enter a valid Number (10 digits).');
        return;
      }

      else if (!isEmailValid) {
        setEmailError('Please enter a valid email address.');
        return;
      }
      else if (!isValidCoupon) { // Check if the coupon code is invalid
        setCouponError('Please enter a valid coupon code.'); // Set the error message for coupon code
        return;
      }
      if(isWalmart){
        formData.page = "Walmart - Pricing"
      } 
      setNameError('');
      setLastNameError('');
      setNumError('');
      setEmailError('');
      setCouponError('')
      pricingSubscription(formData)
        .then((apiData) => {
          if (apiData && apiData.status === 200) {
            setErrorMessage('');
            setSuccessMessage((isWalmart && isValidCoupon && couponCode?.length > 0) ? 
              "Thank you for subscribing to MYREDFOLDER. Your discount coupon has been applied. Our payments team will get in touch with you shortly to complete the subscription process." 
              : 
              'Thank you for registering your interest in MYREDFOLDER subscription. Our payments team will reach out to you within two business days to complete your subscription.');
            setTimeout(() => {
              setSuccessMessage('');
              handleClose();
            }, 5000);
            event.target.reset();
          }
          if (apiData && apiData.status !== 200) {
            setSuccessMessage('')
            setErrorMessage('');
            setErrorMessage(apiData.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

      // setTimeout(() => {
      //   setSuccessMessage("")
      //   handleClose()
      // }, 5000);
      // console.log(formData);

      // event.target.reset();
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      if (name === 'firstName') {
        setNameError('')
      }
      else if (name === 'lastName') {
        setLastNameError('')
      }
      else if (name === 'contactNo') {
        setNumError('')
      }
      else if (name === 'emailId') {
        setEmailError('')
      }
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      height: 630,
      bgcolor: 'background.paper',
      border: "none",
      borderRadius: "5px",
      p: 2,
    };
    const styleone = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,

      bgcolor: 'background.paper',
      border: "none",
      borderRadius: "5px",
      p: 1,
    };
    return <>
      <Modal
        open={onopen} onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 10000 }} >

        <Box >
          {successMessage ? (
            <Box className="pricing-form-alert-popup">
              <Alert variant="filled" severity="success">
                {successMessage}
              </Alert>
            </Box>
          ) : errorMessage ? (
            <Box className="pricing-form-alert-popup">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Box>
          ) :
            <Box className="pricing-form-popup">
              <Box>
                <Box>
                  <div style={{ float: "right", cursor: "pointer" }} onClick={handleClose}>
                    <img
                      src={close}
                      height={20}
                      width={20}
                      alt="close"

                    />
                  </div>
                  <Box >
                    <Typography sx={{ textAlign: "left", ml: "14px !important" }} className="primary-font-bold14"> Subscribe</Typography>
                  </Box>
                </Box>
                <Divider sx={{ height: "2px", mt: "10px" }} />
                <Grid item container xs={12} sm={12} md={6} lg={12} sx={{justifyContent:"space-between"}}>
                   <Grid style={{ display: 'flex', alignItems: 'center', gap: 40}} className="branding-logo">
                    <img src={MYREDFOLDER} alt="myredfolderlogo" id="mrflogo" style={{ marginRight: 10, marginTop: "6px" }} />
                    <img src={WalmartLogo} alt="myredfolderlogo"
                      style={{ marginRight: 10, marginTop: "6px", width: 150, height: 'auto', cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Typography className='primary-font-normal-7' sx={{ paddingY: "20px", ml: "14px" }}>  To subscribe MYREDFOLDER®  {planvalue} plan, please enter your details here. We
                  will contact you for updates. </Typography>
              </Box>
              <Box className='mobile-pricing-popup-content'>

                <form onSubmit={handleSubmit}>
                  <Scrollbars style={{ height: "200px" }}>
                    <Typography className='font-normal-small error-message'>{nameError}</Typography>
                    <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                      First Name<span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      // placeholder="(Required Field)"
                      className={`formStyles ${nameError !== '' ? 'err-input' : ''}`}
                      onChange={handleChange}
                    />
                    <Typography className='font-normal-small error-message'>{lastnameError}</Typography>
                    <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                      Last Name<span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      // placeholder="(Required Field)"
                      className={`formStyles ${lastnameError !== '' ? 'err-input' : ''}`}
                      onChange={handleChange}
                    />
                    <Typography className='font-normal-small error-message'>{numError}</Typography>
                    <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                      Contact Number<span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      name="contactNo"
                      // placeholder="(Required Field)"
                      className={`formStyles ${numError !== '' ? 'err-input' : ''}`}
                      onChange={handleChange}
                    />



                    <div>
                      <Typography className='font-normal-small error-message'>{emailError}</Typography>
                      <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                        Email Address<span className="required-asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        name="emailId"
                        // placeholder="(Required Field)"
                        className={`formStyles ${emailError !== '' ? 'err-input' : ''}`}
                        onChange={handleChange}
                      />
                    </div>
                    {isWalmart && (
                      <>
                      <Typography className='font-normal-small error-message'>{couponError}</Typography>
                      <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                      Coupon Code<span className="required-asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        name="couponCode"
                        className={`formStyles`}
                        onChange={handleChange}
                      />
                      </>
                    )}
                  </Scrollbars>
                  <Divider sx={{ height: "2px", mb: "15px" }} />

                  <Box sx={{ display: "flex", justifyContent: "center", }}>

                    <Button variant="outlined" onClick={handleClose} sx={{
                      mr: "40px",
                      width: '150px !important',
                      height: '50px !important'
                    }} className="pricing-btn" type="submit">
                      Cancel
                    </Button>

                    <Button variant="outlined" sx={{
                      width: '150px !important ',
                      height: '50px !important'
                    }} className="pricing-btn" type="submit">
                      Submit
                    </Button>
                  </Box>

                </form>

              </Box>



              <Box className='laptop-pricing-popup-content' >

                <form onSubmit={handleSubmit}>
                <Scrollbars style={{ height: "320px" }}>
                  <Typography className='font-normal-small error-message'>{nameError}</Typography>
                  <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                    First Name<span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    // placeholder="(Required Field)"
                    className={`formStyles ${nameError !== '' ? 'err-input' : ''}`}
                    onChange={handleChange}
                  />
                  <Typography className='font-normal-small error-message'>{lastnameError}</Typography>
                  <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                    Last Name<span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    // placeholder="(Required Field)"
                    className={`formStyles ${lastnameError !== '' ? 'err-input' : ''}`}
                    onChange={handleChange}
                  />
                  <Typography className='font-normal-small error-message'>{numError}</Typography>
                  <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                    Contact Number<span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    name="contactNo"
                    // placeholder="(Required Field)"
                    className={`formStyles ${numError !== '' ? 'err-input' : ''}`}
                    onChange={handleChange}
                  />



                  <div>
                    <Typography className='font-normal-small error-message'>{emailError}</Typography>
                    <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                      Email Address<span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailId"
                      // placeholder="(Required Field)"
                      className={`formStyles ${emailError !== '' ? 'err-input' : ''}`}
                      onChange={handleChange}
                    />
                  </div>
                  {isWalmart && (
                      <>
                        <Typography className='font-normal-small error-message'>{couponError}</Typography>
                        <label style={{ marginLeft: "14px" }} className="primary-font-normal-7">
                          Coupon Code
                        </label>
                        <input
                          type="text"
                          name="couponCode"
                          className={`formStyles`}
                          onChange={handleChange}
                        />
                    </>
                  )}
                    </Scrollbars>

                  <Divider sx={{ height: "2px", mb: "15px" }} />

                  <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "40px", mr: "30px" }}>

                    <Button variant="outlined" onClick={handleClose} sx={{
                      mr: "40px",
                      width: '150px',
                      height: '50px'
                    }} className='pricing-btn' type="submit">
                      Cancel
                    </Button>

                    <Button variant="outlined" style={{
                      width: '150px',
                      height: '50px'
                    }} className='pricing-btn' type="submit">
                      Submit
                    </Button>
                    
                  </Box>
                </form>
              </Box>
            </Box>}
        </Box>
      </Modal>
    </>
  }


  return (
    <Grid container sx={{ marginBottom: "50px" }}>
      <Grid container className="pricing-holder" sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)" }}>
        <div className='pricing-header-container'>
          <Grid container item xs={12} md={12} lg={12} >

            {/* <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

              <Typography className='pricing-heading-font'>{customisedBannerTitle}</Typography>

            </Grid> */}
            {/* <Grid item xs={12} md={12} lg={12} className='pricing-header-img' >
              <img src={customisedBannerImg} className="pricing-holder-img" alt="text" style={{ width: "100%", height: '100%' }} />
            </Grid> */}       
 <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <img src={customisedBannerImg} alt="text" 
              style={{width:"100%"}}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <div className="pricing-header-container">
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ display: "flex" ,flexDirection:"column" }}
      >
        <Typography className="primary-font-bold11 " sx={{mt:"20px"}} >
          Personal Emergency Plan, Ai-Driven Data Capture, Family Mapping,
          Location Sharing
          </Typography>
          <Typography className="primary-font-normal-2 privacy-para ">
          MYREDFOLDER® is a digital tool empowering families to organize, prepare, and respond to emergencies effectively. 
          </Typography>
          
        <Typography className="primary-font-bold15 privacy-para " >About MYREDFOLDER®</Typography>
        <ul style={{margin:0}} >
          <li>
            <Typography className="primary-font-bold15 privacy-para ">
              {" "}
              Comprehensive Emergency Plans:
            </Typography>
            <Typography className="primary-font-normal-2 ">
              {" "}
              Step-by-step guides to prepare, respond, and recover from any
              disaster.{" "}
            </Typography>
          </li>
          <li>
            <Typography className="primary-font-bold15 privacy-para ">
              {" "}
              Real-Time Alerts:
            </Typography>
            <Typography className="primary-font-normal-2 ">
              {" "}
              Stay informed with up-to-date emergency notifications.{" "}
            </Typography>
          </li>
          <li>
            <Typography className="primary-font-bold15 privacy-para ">
              {" "}
            </Typography>
            <Typography className="primary-font-normal-2 ">
              {" "}
              Family Safety Tracking Easily locate and communicate with loved
              ones in times of crisis.{" "}
            </Typography>
          </li>
          <li>
            <Typography className="primary-font-bold15 privacy-para ">
              Critical Document Storage:{" "}
            </Typography>
            <Typography className="primary-font-normal-2 ">
              {" "}
              Secure access to essential records anytime and anywhere.{" "}
            </Typography>
          </li>
          <li>
            <Typography className="primary-font-bold15 privacy-para ">
              Peace of Mind:{" "}
            </Typography>
            <Typography className="primary-font-normal-2 ">
              {" "}
              Ensure your family is always prepared, no matter the situation{" "}
            </Typography>
          </li>
          </ul>
          <Typography className="primary-font-normal-2 privacy-para ">
          To redeem,use discount code <b className="primary-font-bold4"> WALMARTMRF-600FF</b> at checkout.
          </Typography>
        </Grid>
        </div>

      <div className="pricing-container">
        <Grid item xs={12} md={12} sx={{ background: "#FFFFFF" }}>

          <Box className="heading-text" sx={{ marginX: "auto" }}>
            <Typography className="primary-font-bold-heading" sx={{ textAlign: "center" }}>
              Choose Your Plan
            </Typography>
          </Box>
          <Grid>

            <TableContainer sx={{ overflowX: 'unset' }}>
              <Table className="pricing-table" aria-label="simple table">
                <TableBody>
                  <TableRow className="table-row" >
                    <TableCell className='table-cell' align="right" sx={tableCell}>
                      <Typography sx={{ textAlign: "center" }} className="primary-font-normal-16"> Value </Typography>
                    </TableCell>
                    <TableCell className='table-cell-1' align="center" sx={tableFirstCell}  >
                      <TableRow sx={{ display: 'flex' }} align="center" className="table-row" >
                        <Grid sx={{ width: '100%' }}
                        //  className="pricing-plan-cell"  
                        >
                          <Grid sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end' }}>
                            <Grid>
                              <LightTooltip
                                interactive={isMobile}
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                                arrow placement="bottom" className="primary-font-normal-5" title={"My Red Folder Basic is your go-to app for essential family safety. It's simple and offers a limited number of tools and options. Perfect for those seeking fundamental safety tools, it lays the groundwork for keeping your loved ones connected."}

                              >
                                <img src={info} alt="tool-tip" className='exclamatory-icon-1' />
                              </LightTooltip>
                            </Grid>
                          </Grid>
                          <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography sx={{ textAlign: "center" }} className="primary-font-normal-16"> Basic</Typography>
                          </Grid>
                          <Box sx={{ marginX: "auto" }}>
                            <Button
                              className="pricing-subscribe-btn"
                              variant="outlined" onClick={() => { setPlan("basic"); handleopen() }}
                              sx={{ background: '#fff', color: '#ff3535', '&:hover': { background: '#ff3535', color: '#fff' }, }}
                            >Subscribe now</Button>
                          </Box>
                        </Grid>
                      </TableRow>
                      <TableRow className="table-row-1" sx={{ display: 'flex', mt: "20px", borderTop: ' 1px solid #e0e0e0', backgroundColor: '#FFFDF7' }}>
                        <TableCell className='table-cell' sx={{ width: "100%", borderRight: '1px solid #e0e0e0', borderBottom: '0px' }} >
                          <Typography sx={{ textAlign: "center", color: '#ff3535' }} className="primary-font-bold16">$10.99</Typography>
                          <Typography sx={{ textAlign: "center", color: '#8B8B8B' }} className="primary-font-normal-10">per month</Typography>
                        </TableCell>

                        <TableCell className='table-cell' sx={{ width: "100%", borderBottom: '0px' }} >
                          <Typography sx={{ textAlign: "center", color: '#ff3535' }} className="primary-font-bold16">$119</Typography>
                          <Typography sx={{ textAlign: "center", color: '#8B8B8B' }} className="primary-font-normal-10">per year</Typography>
                        </TableCell>
                      </TableRow>

                    </TableCell>
                    <TableCell className='table-cell-1' align="center" sx={tableFirstCell}>
                      <TableRow sx={{ display: 'flex' }} align="center" className="table-row">
                        <Grid sx={{ width: '100%' }}
                        //  className="pricing-plan-cell"  
                        >
                          <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Grid>
                              <LightTooltip arrow placement="bottom-start" className="primary-font-normal-5" title={"My Red Folder Premium takes family safety to the next level with a range of advanced features. It offers significantly more tools and resources for comprehensive protection and awareness. Ideal for families looking for an extensive safety net, this premium version is a robust guardian in the world of cyber and physical threats. This is the number one key for keeping you and your family alert and aware."}
                                interactive={isMobile}
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                              >
                                <img src={info} alt="tool-tip" className='exclamatory-icon-1' />
                              </LightTooltip>
                            </Grid>
                          </Grid>
                          <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography sx={{ textAlign: "center" }} className="primary-font-normal-16">Premium</Typography>
                          </Grid>
                          <Box sx={{ marginX: "auto" }}>
                            <Button
                              className="pricing-subscribe-btn"
                              variant="outlined" onClick={() => { setPlan("Premium"); handleopen() }} sx={{ background: '#fff', color: '#ff3535', '&:hover': { background: '#ff3535', color: '#fff' }, }} >Subscribe now</Button>

                          </Box>
                        </Grid>
                      </TableRow>

                      <TableRow className="table-row-1" sx={{ display: 'flex', mt: "20px", borderTop: ' 1px solid #e0e0e0', backgroundColor: '#FFFDF7' }}>
                        <TableCell className='table-cell' sx={{ width: "100%", borderRight: '1px solid #e0e0e0', borderBottom: '0px' }} >
                          <Typography sx={{ textAlign: "center", color: '#ff3535' }} className="primary-font-bold16">$29</Typography>
                          <Typography sx={{ textAlign: "center", color: '#8B8B8B' }} className="primary-font-normal-10">per month</Typography>
                        </TableCell>

                        <TableCell className='table-cell' sx={{ width: "100%", borderBottom: '0px' }} >
                          <Typography sx={{ textAlign: "center", color: '#ff3535' }} className="primary-font-bold16">$329</Typography>
                          <Typography sx={{ textAlign: "center", color: '#8B8B8B' }} className="primary-font-normal-10">per year</Typography>
                        </TableCell>
                      </TableRow>



                      {/* <Box sx={{ display: "flex", flexDirection: "column", my: "20px"}}> */}
                      {/* <Typography sx={{ textAlign: "center", color: "#8B8B8B" }} className="primary-font-normal-15">Per Month</Typography> */}
                      {/* </Box> */}


                    </TableCell>
                    {/* <TableCell className='table-cell' align="center" sx={tableFirstCell}>
                      <Box sx={{ minWidth: "175px" }} >
                        <Typography sx={{ textAlign: "center" }} className="primary-font-normal-16">Annual</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", my: "20px" }}>
                          <Typography sx={{ textAlign: "center", color: '#ff3535' }} className="primary-font-bold16">$149.00</Typography>
                          <Typography sx={{ textAlign: "center", color: "#8B8B8B" }} className="primary-font-normal-15">Per Year</Typography>
                        </Box>
                        <Box sx={{ marginX: "auto" }}>
                          <Button variant="outlined" onClick={() => { setPlan("Annual"); handleopen() }} sx={{ borderRadius: '10px', background: '#fff', color: '#ff3535', opacity: 1, borderColor: '#ff3535!important', height: "40px", textTransform: 'unset', '&:hover': { background: '#ff3535', color: '#fff' }, }} >Subscribe now</Button>
                         
                   

                        </Box>
                      </Box>
                    </TableCell>*/}
                    <RenderPopup onClose={handleClose} onopen={open} planvalue={plan} />
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">iOS/Android MYREDFOLDER® APP</Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Essentials at Your Fingertips: Elevate your preparedness game with our mobile app. Your entire emergency plan, now pocket-sized and always with you.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>




                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> Access critical documents<br /> 1: MYREDFOLDER® pdf plan  <br />2: MYREDFOLDER® pdf home inventory  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Access Critical Documents: Organized, Accessible, Essential: No more frantic searching. All your pivotal documents are organized, ready for action when seconds count.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Damage Assessment Forms </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Your Blueprint for Recovery: Accurate damage documentation is not just smart, it is crucial. Ensure smooth insurance claims and speedy recovery with our structured form.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>


                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Structural Analysis Report  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Knowledge to Build On: Understand your home inside and out. Make data-driven decisions that ensure safety and stability post-emergencies.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">MYREDFOLDER® Access to MY FILES  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Your Digital Safety Box: A dedicated space for all your essential files secure, organized, and easily retrievable. Because peace of mind matters.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell} >
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">MYREDFOLDER® MY Business  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title={`Discover "My Business" in MYREDFOLDER®, your digital stronghold for streamlined, confident business navigation. Beyond document management, it safeguards crucial information and ensures resilience through comprehensive insurance details, securing your enterprise against unforeseen losses. Welcome to a future where every aspect of your business is protected and effortlessly accessible.`}>
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell1' align="center" sx={{ border: '1px solid #e0e0e0', color: "black", backgroundColor: "#FFFDF7" }}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell1' align="center" sx={{ border: '1px solid #e0e0e0', color: "black", backgroundColor: "#FFFDF7" }}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">  MYREDFOLDER® MY PET Information </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Because Every Family Member Counts: Your pets deserve the best care during emergencies. Store vital details and ensure they are always protected.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® MY Property(s) Inventory </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Asset Clarity in Chaos: An organized list of your properties is not just convenient, it is essential. Ensure swift actions and claims during unexpected events.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>


                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">  MYREDFOLDER® “Neighbor Helps Neighbor” Agreement  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title=" Strength in Unity: Community ties can make all the difference Our agreement fosters trust, cooperation, and mutual support when it is needed most.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">MYREDFOLDER® MY Family Medications/Medical History  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Health Histories at a Glance: Keeping track of medical details can be lifesaving. Ensure swift and accurate care with all health data in one place.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>



                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® MY Tax Documents  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Financial Recovery, Fast-tracked: Emergencies can strain finances with secure tax document storage, bounce back with confidence and clarity.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® data storage device (discounted additional purchase)</Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Ultimate Data Defense: There is no such thing as too many backups. We ensure every piece of critical information has its safe haven.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Emergency Response eBook  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Guard Your Data Like a Pro: Arm yourself with this essential guide, ensuring every emergency decision you make is a well-informed one.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Certification (Family Preparedness Certificate) </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Prove Your Preparedness: With this certificate, display to the world that your family is not just prepared, but certified ready for any challenge.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>




                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Advance HealthCare Directive  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Your Health, Your Control: In emergencies, nothing matters more than respecting your family's medical wishes. We ensure their needs are prioritized.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® MY Family Trusts Documents </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Legacy Protection simplified: Your family's assets deserve top-tier protection Rest easy knowing they are safeguarded during the unpredictable.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Personal Email Reminders  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Timely Alerts, Every Time: Never miss a beat .Our tailored reminders ensure you are coordinated with all your emergency strategies.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Monthly Newsletter
                        </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="The Pulse of Preparedness: Immerse in monthly insights, tips, and news that keep you a step ahead in your emergency readiness game.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>


                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">   MYREDFOLDER® Family Chat Room (Non-Emergency/Emergency) </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Family Unity, Redefined: In moments of calm or chaos, this dedicated space lets your family rally, support, and strategize together.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9"> MYREDFOLDER® Dedicated Help Desk </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Expertise OnDemand: Emergencies can be daunting, but not when you have our experts ready to assist, advise, and alleviate your concerns.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={eventableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>



                  <TableRow className="table-row" >

                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "start" }} className="primary-font-medium-9">  MYREDFOLDER® Tutorial Video  </Typography>
                        <Box className="exclamatory-icon" sx={{ my: "auto" }}>
                          <LightTooltip
                            interactive={isMobile}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow placement="bottom" className="primary-font-normal-5" title="Mastering MYREDFOLDER® Made Easy: Every tool, every feature, simplified. Dive into our user-friendly guide and get the most out of our platform.">
                            <img src={info} alt="tool-tip" className='exclamatory-icon' />
                          </LightTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}> <img alt="text" className="tick-img" src={wrong} /> </Box>
                    </TableCell>
                    <TableCell className='table-cell' align="center" sx={tableCell}>
                      <Box className="tick-img" sx={{ my: "auto" }}>  <img alt="text" className="tick-img" src={greenTick} /> </Box>
                    </TableCell>
                  </TableRow>



                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </div>
    </Grid>
  );
};


export default CustomisedPricing;